<template>
  <!-- remove && false when active this page -->
  <b-overlay
    :show="show && false"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
    height="100%"
  >
    <b-card>
      <h3>{{ $t('thisPageIsInactiveForNow-deposit') }}</h3>
      <b-button
        class="d-flex justify-content-center align-items-center btn-style w-50 mx-auto mt-3"
        variant="info"
        style="height: 100px;"
        :to="`/${$i18n.locale}/crypto-wallets`"
      >
        <h4
          class="text-white"
        >
          {{ $t('crypto Deposit') }}
        </h4>
      </b-button>
    </b-card>
    <!-- remove v-if="false" when active this page -->
    <b-card
      v-if="false"
      class="d-flex justify-content-center"
    >
      <b-row v-if="alertShow">
        <b-col sm="12">
          <b-alert
            variant="success"
            show
            class="px-1"
          >
            <div class="alert-body p-2">
              <h4>
                {{ $t('successful-deposit-registration') }}
              </h4>
            </div>
          </b-alert>

        </b-col>
      </b-row>
      <div class="pricing-free-trial">
        <b-row>
          <b-col
            lg="12"
            offset-lg="3"
            class="mx-auto"
          >
            <div class="pricing-trial-content d-flex justify-content-between">
              <div class="text-md-left mt-3 w-100">
                <h3 class="text-primary">
                  {{ $t('wireDepositTitle') }}
                </h3>
                <h5> {{ $t('wireDepositDescription') }}</h5>
                <div
                  v-if="!ShowAedBankInfo"
                  class="mx-auto mx-md-0"
                  style="max-width: 600px;"
                >
                  <b-card class="bg-dark-blue text-white">
                    <b-row
                      class="d-flex"
                      dir="rtl"
                    >
                      <b-col
                        v-if="bankAccountInfo"
                        class="text-center text-lg-right w-75"
                      >
                        <span class="pa-2 d-block mb-1 d-block d-lg-none ">{{ $t('accountNumber') }}</span>
                        <span class="pa-2 d-block">{{ bankAccountInfo.card_number }}</span>
                        <br>
                        <span class="pa-2 d-block mb-1 d-block d-lg-none">{{ $t('iban') }}</span>
                        <span class="pa-2 d-block">{{ bankAccountInfo.iban }}</span>
                        <br>
                        <span class="pa-2 d-block mb-1 d-block d-lg-none">{{ $t('accountOwner') }}</span>
                        <span class="pa-2 d-block">{{ bankAccountInfo.card_name }}</span>
                        <br>
                        <span class="pa-2 d-block mb-1 d-block d-lg-none">{{ $t('bank') }}</span>
                        <span class="pa-2 d-block"> {{ bankAccountInfo.bank_name }}</span>
                      </b-col>
                      <b-col
                        cols="1"
                        class="text-center text-lg-right w-25  d-none d-lg-block"
                      >
                        <span class="pa-2  d-block">:</span>
                        <br>
                        <span class="pa-2 d-block">:</span>
                        <br>
                        <span class="pa-2 d-block">:</span>
                        <br>
                        <span class="pa-2 d-block">:</span>
                      </b-col>
                      <b-col
                        cols="3"
                        class="text-center text-lg-right w-25  d-none d-lg-block"
                      >
                        <span class="pa-2 text-nowrap d-block">{{ $t('accountNumber') }}</span>
                        <br>
                        <span class="pa-2 d-block">{{ $t('iban') }}</span>
                        <br>
                        <span class="pa-2 d-block">{{ $t('accountOwner') }}</span>
                        <br>
                        <span class="pa-2 d-block">{{ $t('bank') }}</span>
                      </b-col>

                    </b-row>
                  </b-card>
                </div>
                <div
                  v-if="ShowAedBankInfo"
                  class="mx-auto mx-md-0"
                  style="max-width: 700px;"
                >
                  <b-card class="bg-dark-blue text-white">
                    <b-row class="d-flex">

                      <b-col
                        v-if="bankAccountInfo"
                        class="text-center text-lg-right w-75"
                      >
                        <span class="pa-2 d-block mb-1 d-block d-lg-none">{{ $t('accountNumber') }}</span>
                        <span class="pa-2 d-block text-nowrap">0012-113274-001</span>
                        <br>
                        <span class="pa-2 d-block mb-1 d-block d-lg-none">{{ $t('iban') }}</span>
                        <span class="pa-2 d-block text-nowrap">AE200400000012113274001</span>
                        <br>
                        <span class="pa-2 d-block mb-1 d-block d-lg-none">{{ $t('accountOwner') }}</span>
                        <span class="pa-2 d-block text-nowrap">Master Line General Trading LLC</span>
                        <br>
                        <span class="pa-2 d-block mb-1 d-block d-lg-none">{{ $t('bank') }}</span>
                        <span class="pa-2  d-block ">National Bank of Ras-Al Kaimah <span class="text-nowrap">(RAK
                          Bank)</span></span>
                        <br>
                        <span class="pa-2 d-block mb-1 d-block d-lg-none">Swift Code</span>
                        <span class="pa-2 d-block text-nowrap">NRAKAEAK</span>
                      </b-col>
                      <b-col
                        cols="1"
                        class="w-25 text-center text-lg-right d-none d-lg-block"
                      >
                        <span class="pa-2  d-block">:</span>
                        <br>
                        <span class="pa-2 d-block">:</span>
                        <br>
                        <span class="pa-2 d-block">:</span>
                        <br>
                        <span class="pa-2 d-block">:</span>
                        <br>
                        <span class="pa-2 d-block">:</span>
                      </b-col>
                      <b-col
                        cols="3"
                        class="w-25 text-center text-lg-right d-none d-lg-block"
                      >
                        <span class="pa-2  d-block">{{ $t('accountNumber') }}</span>
                        <br>
                        <span class="pa-2 d-block">{{ $t('iban') }}</span>
                        <br>
                        <span class="pa-2 d-block">{{ $t('accountOwner') }}</span>
                        <br>
                        <span class="pa-2 d-block">{{ $t('bank') }}</span>
                        <br>
                        <span class="pa-2 d-block">Swift Code</span>
                      </b-col>
                    </b-row>
                  </b-card>
                </div>
              </div>
              <!-- images -->
              <b-img
                fluid
                :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
                class="pricing-trial-img d-none d-md-block"
                alt="svg img"
              />
              <!--/ images -->
            </div>
          </b-col>
        </b-row>
      </div>
      <hr>
      <b-alert
        show
        class="p-1"
      >
        <p>{{ $t('convert_rate') }}: <span dir="ltr">{{ usdSellPrice+' '+currency.from_currency }}</span></p>
      </b-alert>
      <validation-observer ref="depositform">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                label-for="Currency"
                :label="$t('fiat_wallet_deposit_currency')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('fiat_wallet_deposit_currency')"
                  vid="Currency"
                  rules="required"
                  :required="!currency"
                >
                  <v-select
                    id="Currency"
                    v-model="currency"
                    :options="options"
                    :placeholder="$t('fiat_wallet_deposit_currency')"
                    label="from_currency"
                    track-by="name"
                    @input="onCurrencyChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              sm="12"
              xl="6"
            >
              <b-form-group
                :label="$t('fiat_wallet_deposit_amount')"
                label-for="hear-description"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('fiat_wallet_deposit_amount')"
                  rules="required"
                >

                  <b-form-input
                    v-model="amount"
                    name="hear-description"
                    :placeholder="$t('fiat_wallet_deposit_amount')"
                    autocomplete="nope"
                    @keypress="onlyNumbers($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-col class="p-0">
            <b-form-group
              label-size="lg"
              :label="$t('deposit_Receipt')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('deposit_Receipt')"
                :rules="'required|size:'+MAX_IMAGE_UPLOAD_SIZE"
              >
                <b-form-file
                  id="file-large"
                  v-model="file"
                  size="lg"
                  accept="image/png, image/jpg, image/jpeg"
                  :placeholder="$t('fiat_wallet_deposit_receipt_file')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-form>
      </validation-observer>
      <b-col cols="12">
        <b-button
          v-if="!pending"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          :disabled="!bankAccountInfo"
          @click="submit"
        >
          {{ $t('fiat_wallet_deposit_send_button') }}
        </b-button>
        <b-button
          v-if="pending"
          variant="primary"
          class="mt-1 mr-1"
          disabled
        >
          <b-spinner
            small
            type="grow"
          />
          {{ $t('fiat_wallet_deposit_loading') }}
        </b-button>
      </b-col>
    </b-card>
  </b-overlay>

</template>
<script>
import {
  BOverlay, BFormInput, BFormGroup, BCard, BRow, BCol, BImg, BFormFile, BButton, BForm, BSpinner, BAlert,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, size } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { alphanumeric } from '@/mixins/alphanumeric'
import FiatApis from '@/services/apis/fiat'
import ProfileApis from '@/services/apis/profile'
import { toEnglishDigits } from '@/utils/index'

const depositCurrency = new FiatApis()
const profile = new ProfileApis()
export default {
  components: {
    BOverlay,
    BFormInput,
    BForm,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BImg,
    BFormFile,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BButton,
    BAlert,
  },
  directives: {
    Ripple,
  },
  mixins: [alphanumeric],
  data() {
    return {
      show: false,
      alertShow: false,
      pending: false,
      required,
      size,
      options: [],
      currency: '',
      amount: '',
      file: [],
      bankAccountInfo: null,
      ShowAedBankInfo: false,
      usdSellPrice: null,
    }
  },
  computed: {
    MAX_IMAGE_UPLOAD_SIZE() {
      return process.env.VUE_APP_MAX_IMAGE_UPLOAD_SIZE
    },
  },
  async created() {
    this.getCurrency()
  },
  methods: {
    async submit() {
      if (await this.$refs.depositform.validate()) {
        this.pending = true
        const data = {
          currency_id: this.currency.from_currency_id,
          amount: toEnglishDigits(this.amount),
          file: this.file,
        }
        await depositCurrency.saveDeposit(data).then(async res => {
          this.$swal({
            title: res.data?.message,
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          })
          await profile.get()
          this.alertShow = true
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        }).finally(() => {
          this.pending = false
        })
      }
    },

    onCurrencyChange() {
      this.ShowAedBankInfo = this.currency.from_currency === 'AED'
      this.getCurrency()
    },
    async getCurrency() {
      this.show = true
      await depositCurrency.getCurrency().then(res => {
        this.options = res.data.results.form.reverse()
        // eslint-disable-next-line prefer-destructuring
        if (!this.currency) {
          // eslint-disable-next-line prefer-destructuring
          this.currency = res.data.results.form[0]
        }
        this.usdSellPrice = res.data.results.prices.find(item => item.from_currency === this.currency.from_currency).sell
        this.ShowAedBankInfo = false
      }).finally(() => {
        if (!this.ShowAedBankInfo) {
          depositCurrency.bankAccountInfo().then(res => {
            this.bankAccountInfo = res.data.results
          }).finally(() => {
            this.show = false
          })
        }
      })
    },
  },
}
</script>
<style scoped>
.bg-dark-blue {
  background-color: rgb(2, 58, 110);
}
</style>
